import React, {useState, useEffect} from 'react';
import './Slideshow.css';

function Slideshow() {
    const [currentImageIndex, setCurrentImageIndex] = useState(0);
    const [images, setImages] = useState([]);

    // Fetch operation to get images
    useEffect(() => {
        fetch('https://api.dergraf.meme/')
            .then(response => response.json())
            .then(data => {
                setImages(data);
            })
            .catch(error => console.error('Error:', error));
    }, []);

    // Slide change interval
    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
        }, 5000);
        return () => clearInterval(interval);
    }, [images]);  // The interval depends on the images

    return (
        <div className="slideshow">
            {images.map((image, index) => (
                <img
                    key={image}
                    className={currentImageIndex === index ? 'active' : ''}
                    src={`https://i.dergraf.meme/${image}`}
                    alt=""
                />
            ))}
        </div>
    );
}

export default Slideshow;